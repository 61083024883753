const FooterData = {
  CompanyWidget: [
    {
      id: 1,
      title: "Ils sont satisfaits, pourquoi pas vous ?",
    },
  ],
  AboutWidget: [
    // {
    //   id: 1,
    //   title: "Download",
    //   menuItems: [
    //     {
    //       id: 1,
    //       url: "#",
    //       text: "Company",
    //     },
    //     {
    //       id: 2,
    //       url: "#",
    //       text: "Android App",
    //     },
    //     {
    //       id: 3,
    //       url: "#",
    //       text: "ios App",
    //     },
    //     {
    //       id: 4,
    //       url: "#",
    //       text: "Desktop",
    //     },
    //     {
    //       id: 5,
    //       url: "#",
    //       text: "Projects",
    //     },
    //     {
    //       id: 6,
    //       url: "#",
    //       text: "My tasks",
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   title: "Help",
    //   menuItems: [
    //     {
    //       id: 1,
    //       url: "#",
    //       text: "FAQ",
    //     },
    //     {
    //       id: 2,
    //       url: "#",
    //       text: "Term & conditions",
    //     },
    //     {
    //       id: 3,
    //       url: "#",
    //       text: "Reporting",
    //     },
    //     {
    //       id: 4,
    //       url: "#",
    //       text: "Documentation",
    //     },
    //     {
    //       id: 5,
    //       url: "#",
    //       text: "Support Policy",
    //     },
    //     {
    //       id: 6,
    //       url: "#",
    //       text: "Privacy",
    //     },
    //   ],
    // },
  ],
  termsCondition: [
    {
      id: 1,
      title: "",
      menuItems: [
        {
          id: 1,
          url: "#",
          text: "Mentions légales",
        },
        {
          id: 2,
          url: "#",
          text: "Politique de confidentialité",
        },
        {
          id: 3,
          url: "#",
          text: "Conditions générales d'utilisation",
        },
      ],
    },
  ],
  SocialLinks: [
    {
      id: 1,
      title: "Team Solutions",
      menuItems: [
        {
          id: 1,
          url: "#",
          icon: "ti-facebook",
        },
        {
          id: 2,
          url: "#",
          icon: "ti-twitter-alt",
        },
        {
          id: 3,
          url: "#",
          icon: "ti-vimeo-alt",
        },
        {
          id: 4,
          url: "#",
          icon: "ti-pinterest",
        },
      ],
    },
  ],
  socialIcon: [
    {
      id: 1,
      url: "https://www.facebook.com/",
      icon: "ti-facebook",
    },
    {
      id: 2,
      url: "#",
      icon: "ti-twitter-alt",
    },
    {
      id: 3,
      url: "#",
      icon: "ti-vimeo-alt",
    },
    {
      id: 4,
      url: "#",
      icon: "ti-pinterest",
    },
  ],
  copywrite: "© Mon Test Auditif - 2021 - Tous droits réservés.",
  TestimonialText: [
    {
      id: 1,
      image: "new/member_01.jpg",
      description:
        "J’étais sceptique quant à l’efficacité de ce test auditif en ligne, ce dernier m’a indiqué une que j’avais une déficience auditive. J’ai par la suite pris rendez-vous avec un audioprothésiste qui me l’a confirmé. Je recommande !",
      authorName: "Marise Dufour",
      authorPost: "Retraité",
    },
    {
      id: 2,
      image: "new/member_01.jpg",
      description:
        "Test auditif en ligne très intuitif comparé à d’autres plateformes, il ne m’a pas décelé de déficience mais je suis très soulagé de l’avoir fait !",
      authorName: "Lucien Valet",
      authorPost: "Cadre en entreprise",
    },
    {
      id: 3,
      image: "new/member_01.jpg",
      description:
        "Ce test auditif m’a permis de déceler un problème auditif, je suis en attente de mon rendez-vous avec un audioprothésiste recommandé par le site. Pour ce qui est du test, les questions sont claires et compréhensibles, je vous conseil vivement de le faire.",
      authorName: "Joséphine Poussai",
      authorPost: "Retraité",
    },
  ],
  aboutLinks: [
    {
      id: 1,
      url: "#",
      item: "Developer",
    },
    {
      id: 2,
      url: "#",
      item: "Blog",
    },
    {
      id: 3,
      url: "#",
      item: "Investor",
    },
    {
      id: 4,
      url: "#",
      item: "Sitemap",
    },
    {
      id: 5,
      url: "#",
      item: "Jobs",
    },
  ],
  helpLinks: [
    {
      id: 1,
      url: "#",
      item: "Help and Contact",
    },
    {
      id: 2,
      url: "#",
      item: "Fees",
    },
    {
      id: 3,
      url: "#",
      item: "Security",
    },
    {
      id: 4,
      url: "#",
      item: "App",
    },
    {
      id: 5,
      url: "#",
      item: "Shop",
    },
  ],
  PrivacyLinks: [
    {
      id: 1,
      url: "#",
      item: "Privacy Policy",
    },
    {
      id: 2,
      url: "#",
      item: "Legal Agreement",
    },
    {
      id: 3,
      url: "#",
      item: "Feedback",
    },
  ],
  about: [
    {
      id: 1,
      url: "#",
      text: "Company",
    },
    {
      id: 2,
      url: "#",
      text: "Leadership",
    },
    {
      id: 3,
      url: "#",
      text: "Diversity",
    },
    {
      id: 4,
      url: "#",
      text: "Jobs",
    },
    {
      id: 5,
      url: "#",
      text: "Press",
    },
    {
      id: 6,
      url: "#",
      text: "Wavelength",
    },
  ],
  Solution: [
    {
      id: 1,
      url: "#",
      text: "Project Management",
    },
    {
      id: 2,
      url: "#",
      text: "Agile",
    },
    {
      id: 3,
      url: "#",
      text: "Task Management",
    },
    {
      id: 4,
      url: "#",
      text: "Reporting",
    },
    {
      id: 5,
      url: "#",
      text: "Work Tracking",
    },
    {
      id: 6,
      url: "#",
      text: "See All Uses",
    },
  ],
  teamSolution: [
    {
      id: 1,
      url: "#",
      text: "Engineering",
    },
    {
      id: 2,
      url: "#",
      text: "Designers",
    },
    {
      id: 3,
      url: "#",
      text: "Sales",
    },
    {
      id: 4,
      url: "#",
      text: "Developers",
    },
    {
      id: 5,
      url: "#",
      text: "Marketing",
    },
    {
      id: 6,
      url: "#",
      text: "See All team",
    },
  ],
};
export default FooterData;
