import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import CrmBanner from "../components/Banner/CrmBanner";
import Stservice from "../components/Service/Sservice/StartupService";
import Testimonial from "../components/Testimonial/Testimonial";
import Cprogress from "../components/CircleProgressbar";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";
import Features from "../components/Features/Features";
import FooterErp from "../components/Footer/FooterErp";
import FooterSecurity from "../components/Footer/FooterSecurity";

const HomeCRM = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <CrmBanner />
      <Features />
      <Cprogress />
      <Testimonial
        tClass="testimonial_area_four sec_pad"
        FooterData={FooterData}
      />
      <FooterSecurity FooterData={FooterData} />
    </div>
  );
};
export default HomeCRM;
