import React, { Component, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";

class CustomNavbar extends Component {
  render() {
    var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;

    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Link className={`navbar-brand ${slogo}`} to="/">
                <img
                  src={require("../img/logo-mon-test-auditif-blanc.png")}
                  alt=""
                  height="100px"
                />
                <img
                  src={require("../img/logo-mon-test-auditif-couleur.png")}
                  alt="logo"
                  height="100px"
                />
              </Link>
              <button
                className="navbar-toggler collapsed "
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div
                className="collapse navbar-collapse d-flex flex-row-reverse"
                id="navbarSupportedContent"
              >
                <a
                  className={`btn_get btn_hover ${hbtnClass} ft-lg`}
                  href="https://app.mon-test-auditif.fr/?ref=landing"
                >
                  Démarrer le test auditif
                </a>
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default CustomNavbar;
