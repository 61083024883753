import React, { Component } from "react";

class Cta extends Component {
  render() {
    var { text } = this.props;

    return (
      <div
        className={
          !text
            ? "action_btn d-flex align-items-center mt_40 wow fadeInRight"
            : "action_btn d-flex justify-content-center mt_40 wow fadeInRight"
        }
        data-wow-delay="0.6s"
      >
        <a
          href="https://app.mon-test-auditif.fr/?ref=landing"
          className="btn_hover app_btn btn-lg"
        >
          {text || "Faire le test"} <i className="ti-arrow-right"></i>
        </a>
      </div>
    );
  }
}
export default Cta;
