import React, { Component } from "react";
import Cta from "../Banner/Cta";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            Obtenez votre réponse en <br /> 3 étapes simples
          </h2>
          <div className="features_info">
            <img
              className="dot_img"
              src={require("../../img/home4/dot.png")}
              alt=""
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="test-auditif-resultat.png"
              iImg="icon01.png"
              ftitle="Faites le test"
              descriptions="Oreille gauche puis oreille droite, indiquez lorsque vous entendez le son"
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="work1.png"
              iImg="icon02.png"
              ftitle="Obtenez vos résultats"
              descriptions="Bonne ou mauvaise audition, vous connaitrez vos points forts et vos points faibles"
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="test-auditif-audioprothese.png"
              iImg="icon3.png"
              ftitle="Décidez de la suite"
              descriptions="Prenez rendez-vous avec un audioprothésiste ou restez-en là"
            />
            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
            <Cta text="Essayer gratuitement" />
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
